body {
  background: #222;
  color: #fff;
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.main {
  /* width: 600px; */
  padding: 20px;
  border-radius: 10px;
  background: #333;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #222;
  display: flex;
  flex-direction: column;
  align-items: center;
;
}

h1 {
  font-size: 36px;
  margin-bottom: 20px;
}

form {
  display: flex;
  margin-bottom: 20px;
}

input[type='text'] {
  outline: none;
  padding: 10px;
  border-radius: 4px;
  border: none;
  background-color: #222;
  color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

input[type='text']::placeholder {
  color: #aaa;
}

button {
  padding: 10px 20px;
  border-radius: 4px;
  border: none;
  background-color: #555;
  color: #fff;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

button:hover {
  background-color: #777;
}

.weather-info {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.weather-info h2 {
  font-size: 24px;
  margin-bottom: 10px;
  text-align: center;
}

.weather-info p {
  margin: 5px 0;
}

.weather-icon {
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
}

.weather-description {
  font-size: 18px;
}

.weather-temperature {
  font-size: 48px;
  font-weight: bold;
  margin: 10px 0;
}

.weather-details {
  display: flex;
  justify-content: space-between;
  width: 200px;
}

.weather-details p {
  margin: 5px 0;
}

.weather-details .label {
  color: #888;
}

.weather-details .value {
  color: #fff;
  font-weight: bold;
}

.error-message {
  color: #ff5555;
  font-size: 14px;
  margin-top: 10px;
}

@media screen and (max-width: 600px) {
  h1 {
    font-size: 28px;
  }

  .weather-info h2 {
    font-size: 20px;
  }

  .weather-icon {
    width: 80px;
    height: 80px;
  }

  .weather-description {
    font-size: 16px;
  }

  .weather-temperature {
    font-size: 36px;
  }

  .weather-details {
    width: 160px;
  }
}

a {
  color: #fff;
  text-decoration: none;
font-weight: 900;
}